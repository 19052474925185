*
{
    margin: 0;
    padding: 0;

}
.webgl
{
    position: absolute;
    z-index: 0;
    outline: none;
}